@import "./main.scss";

.home {
  p {
    margin-block-end: 1rem;
  }

  &__title {
    text-align: center;
  }

  &__result {
    display: flex;
    gap: 1rem;
  }

  &__result-image {
    display: none;
  }

  @include mq($from: desktop) {
    &__result {
      margin-block-start: 6rem;
      padding-block-start: 2rem;
    }

    &__result-image {
      max-height: 90vh;
      display: block;
    }

    &__result-details {
      flex: 1;
    }
  }
}
