.home p {
  margin-block-end: 1rem;
}

.home__title {
  text-align: center;
}

.home__result {
  gap: 1rem;
  display: flex;
}

.home__result-image {
  display: none;
}

@media (width >= 61.25em) {
  .home__result {
    margin-block-start: 6rem;
    padding-block-start: 2rem;
  }

  .home__result-image {
    max-height: 90vh;
    display: block;
  }

  .home__result-details {
    flex: 1;
  }
}

/*# sourceMappingURL=index.dbdb037e.css.map */
